import _ from 'lodash';
import React, { Fragment, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';
import { getUrl } from '@utils/ApiAction';
import { storeProfile } from '@actions';
import ScrollToTop from '@utils/ScrollToTop';

//NOTIFICATON AND LOADING
import NotificationLoadingProvider from "@providers/NotificationLoadingProvider";
import NotificationLoading from "@layouts/NotificationLoading";
import TitleBar from '@layouts/TitleBar';
import { public_restricted_routes, public_routes, private_routes, imported_user_restricted_routes } from '@configs/Router';

import '@css/styles.css';
import '@css/util.css';

export default function App(props) {
    const { accessToken, importedUser } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let location = useLocation();
    let maintenance = location.pathname === '/maintenance' ? true : false;

    // useEffect(() => {
    //     if (accessToken && !maintenance) {
    //         getUrl('/user').then(response => {
    //             if (response.status) {
    //                 dispatch(storeProfile(response.data));
    //             } else {
    //                 addAlert('', t('error.contactSupport'), 'error', '');
    //             }
    //         }).catch((error) => {
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [accessToken, maintenance]);

    const renderPublicRoute = useCallback(() => {
        return public_routes.map((route, index) =>
            <Route key={index} element={route.component} path={route.path} />
        )
    }, []);

    const renderAuthRestrictedRoute = useCallback(() => {
        return public_restricted_routes.map((route, index) =>
            <Route key={index} element={accessToken ? <Navigate to="/" replace={true} /> : route.component} path={route.path} />
        )
    }, [accessToken]);

    const renderPrivateRoute = useCallback(() => {
        return private_routes.map((route, index) =>
            <Route key={index} element={accessToken ? route.component : <Navigate to="/landing" replace={true} />} path={route.path} />
        )
    }, [accessToken]);

    const renderImportedUserRestrictedRoute = useCallback(() => {
        return imported_user_restricted_routes.map((route, index) =>
            <Route key={index} element={accessToken ? route.component : <Navigate to="/landing" replace={true} />} path={route.path} />
        )
    }, [accessToken]);

    return (
        <Fragment>
            <NotificationLoadingProvider>
                <ScrollToTop />
                <NotificationLoading />
                {!maintenance ? <TitleBar showTitle={true} /> : null}
                {
                    importedUser ?
                        <Routes>
                            {renderPublicRoute()}
                            {renderAuthRestrictedRoute()}
                            {renderImportedUserRestrictedRoute()}
                            <Route path="*" element={accessToken ? <Navigate replace to="/setting/profile" /> : <Navigate replace to="/landing" />} />
                        </Routes>
                        :
                        <Routes>
                            {renderPublicRoute()}
                            {renderAuthRestrictedRoute()}
                            {renderPrivateRoute()}
                            <Route path="*" element={accessToken ? <Navigate replace to="/" /> : <Navigate replace to="/landing" />} />
                            {/* <Route path="*" element={<NotFound />} /> */}
                        </Routes>
                }
            </NotificationLoadingProvider>
        </Fragment>
    );
};