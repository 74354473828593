import React, { memo, useState, useEffect } from 'react';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import _ from 'lodash';
// import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@mui/styles';
import { AppBar, Toolbar, IconButton, Box, Link, Hidden, Divider, Drawer, List, Container } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes, useParams } from 'react-router-dom';

import { changeLanguage } from '@actions';
import moment from 'moment-timezone';
import { reducePaths, storePaths } from '@utils/Tools';

import MenuIcon from '@mui/icons-material/Menu';
import { FiChevronLeft } from "react-icons/fi";



// const systemLanguages = ['en', 'cn', 'id', 'kr', 'jp', 'th', 'vi'];

const INITIAL_STATE = { showTutorBtn: false, showBackBtn: false, backUrl: null, tutorUrl: null, showLogo: true, noHeader: false };

const TitleBar = memo((props) => {

    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [option, setOption] = useState(INITIAL_STATE);
    const [anchorEl, setAnchorEl] = useState(null);
    // const { accessToken } = useSelector(state => state.general);
    // const { email, name } = useSelector(state => state.user);
    const open = anchorEl?.attributes?.['data-ref']?.value;//Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event = null) => {
        if (event) event.stopPropagation()
        setAnchorEl(null);
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let { id } = useParams();

    const [serverTime, setServerTime] = useState("");

    useEffect(() => {
        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackBtn: public_restricted_detail?.showBackBtn, showTutorBtn: public_restricted_detail?.showTutorBtn, showLogo: public_restricted_detail?.showLogo, tutorUrl: public_restricted_detail?.tutorUrl, backUrl: public_restricted_detail?.backUrl, backTitle: t(public_restricted_detail?.backTitle), noHeader: public_restricted_detail?.noHeader });

        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackBtn: public_detail?.showBackBtn, showTutorBtn: public_detail?.showTutorBtn, showLogo: public_detail?.showLogo, tutorUrl: public_detail?.tutorUrl, backUrl: public_detail?.backUrl, backTitle: t(public_detail?.backTitle), noHeader: public_detail?.noHeader });

        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackBtn: private_detail?.showBackBtn, showTutorBtn: private_detail?.showTutorBtn, showLogo: private_detail?.showLogo, tutorUrl: private_detail?.tutorUrl, backUrl: private_detail?.backUrl, backTitle: t(private_detail?.backTitle), noHeader: private_detail?.noHeader });

        } else {
            setOption(INITIAL_STATE);
        }

    }, [current_path, t]);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    useEffect(() => {
        if (i18n.language == "ur") {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr';
        }
        document.documentElement.lang = i18n.language;
        // eslint-disable-next-line
    }, [i18n.language]);

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    // const logout = () => {
    //     removeLoginAccess();
    //     navigate('/landing');
    // };

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawerWidth = 240;
    const navItems = [
        {
            title: 'Features',
            link: '/#features',
            target: '_top',
        },
        {
            title: 'Why Us?',
            link: '/#why',
            target: '_top',
        },
        {
            title: 'Get Started',
            link: '/#start',
            target: '_top',
        },
        {
            title: 'Guide',
            link: '/#guides',
            target: '_top',
        },
        {
            title: 'Faq',
            link: '/#faq',
            target: '_top',
        },

    ];

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className='p-t-20 p-b-15'>
                <img src='/logo.png' alt="logo" style={{ height: 25 }} />
            </div>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <div key={item.title} >
                        <Link to={item.link} target={item.target} underline='none' component={RouterLink} >
                            <div className='p-tb-20 txt-left p-lr-20'>
                                <p className='cl-dark'>{item.title} </p>
                            </div>
                        </Link>
                    </div>
                ))}
                {/* <div className='p-lr-20 p-t-20'>
                    <Link to='/' underline='none' component={RouterLink} >
                        <div className='p-tb-10 p-lr-20 bg-theme bor10 translateY'>
                            <p className='clwhite'>Sign Up</p>
                        </div>
                    </Link>
                </div> */}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        option?.noHeader === true ?
            <div className='flex-sb-m p-lr-26 p-t-20 p-b-10'>
                {option?.showBackBtn &&
                    <div style={{ zIndex: 3 }}>
                        {option?.backUrl ?
                            <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                            </Link>
                            : <div className='flex-m pointer txt-white' onClick={goBack}>
                                <FiChevronLeft /><p className='p-l-10 fs-50'>{option?.backTitle}</p>
                            </div>}

                    </div>
                }
                <div></div>
                {/* <div className='txt-right txt-white p-r-5 fs-12 lh-12' style={{ letterSpacing: '-0.2px' }}>
                    <p>{t('general.serverTime')}</p>
                    <p className='p-t-5'>{serverTime}</p>
                </div> */}
            </div>
            :
            <>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
                <div >
                    <AppBar position='fixed' sx={{ maxWidth: '100%', right: 'auto' }}>
                        <Container>
                            <Toolbar className='flex-sb-m w-full'>
                                {
                                    option?.showLogo &&

                                    <div className='p-t-6'>
                                        {/* <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} > */}
                                        <img src='/logo.png' alt="logo" style={{ height: 25 }} />
                                        {/* </Link> */}
                                    </div>
                                }
                                <div className='cl-dark inter-normal fs-14 flex-m'>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2, display: { sm: 'none' } }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Box className="w-full " sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <div className='w-full flex-m'>
                                            {navItems.map((item) => (
                                                <div key={item.title} className='p-l-15'>
                                                    <Link to={item.link} target={item.target} underline='none' component={RouterLink} state={{ from: storePaths(location) }}>
                                                        <p className='cl-dark'>{item.title}</p>
                                                    </Link>
                                                </div>
                                            ))}
                                            {/* <div className='p-l-15'>
                                                <Link to='/' underline='none' component={RouterLink} >
                                                    <div className='p-tb-10 p-lr-20 bg-theme bor10 translateY'>
                                                        <p className='clwhite'>Sign Up</p>
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </Box>
                                </div>
                            </Toolbar>

                            {option?.showBackBtn &&
                                <div className='flex-sb-m p-lr-15 p-tb-10'>
                                    <div style={{ zIndex: 3 }}>
                                        {option?.backUrl ?
                                            <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                                <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                            </Link>
                                            : <div className='flex-m pointer txt-white' onClick={goBack}>
                                                <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                            </div>}

                                    </div>
                                </div>
                            }
                        </Container>
                    </AppBar>
                    {option?.showBackBtn ?
                        <div className='m-t-140'>
                        </div>
                        :
                        <div style={{ lineHeight: 0 }}>
                            <Hidden only={'xs'}>
                                <div className='m-t-64'></div>
                            </Hidden>
                            <Hidden smUp>
                                <div className='m-t-56'></div>
                            </Hidden>
                        </div>
                    }
                </div>
            </>
    )
});

const useStyles = makeStyles((theme) => ({
}));

export default TitleBar;